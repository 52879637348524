import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc, addDoc, serverTimestamp, collection, where, query, getDocs, deleteDoc } from "firebase/firestore";
import { db, firestore, functions, httpsCallable } from "./firebase";
import axios from 'axios';
export default class FBQueries {
    static async fetchBooking(bookingid, completion) {
        try {
            // Reference to the specific document in the collection
            const docRef = doc(db, "bookings", bookingid);

            // Fetch the document
            const docSnap = await getDoc(docRef);

            // Check if the document exists
            if (docSnap.exists()) {
                // Document data will be in docSnap.data()
                completion(docSnap.data())
            } else {
                completion(null)
                // Document not found
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    }

    static async approveBooking(id, user, completion) {
        try {
            let data = {
                approved: true,
                approvedAt: serverTimestamp()
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            FBQueries.cancelScheduledFunction(id)
            // setTimeout(async() => {
            const driverArrival = httpsCallable(functions, 'scheduleDriverArrivedTask');
            const result = await driverArrival({ bookingID: id, userid: user.uid, token: user.token });
            // }, 2000);
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async rejectBooking(id, completion) {
        try {
            let data = {
                rejected: true,
                status: 'cancelled',
                bookingFinished: true,
                cancelReason: 'rejected by driver',
                rejectedAt: serverTimestamp()
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            //FBQueries.deleteDocumentsWithCondition(id)
            FBQueries.cancelScheduledFunction(id)
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async modifyBooking(id, eta, price, updatedprice, completion) {
        try {
            let data = {
                bookingModified: (updatedprice != price) ? true : false,
                eta: eta,
                price: updatedprice,
                modifiedAt: serverTimestamp()
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async cancelScheduledFunction(id) {
        const collectionRef = collection(db, "scheduledTasks");

        // Create the query with where condition
        const q = query(collectionRef, where("bookingid", "==", id));
        const cancelTask = httpsCallable(functions, 'cancelTask');
        try {
            // Fetch the documents
            const querySnapshot = await getDocs(q);

            // Loop through and log each document
            querySnapshot.forEach(async (doc) => {
                //doc.data()
                let taskName = doc.data().taskName
                await cancelTask({ taskName: taskName });
            });
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    };

    static async fetchUserDetails(uid, completion) {
        try {
            // Reference to the specific document in the collection
            const docRef = doc(db, "users", uid);

            // Fetch the document
            const docSnap = await getDoc(docRef);

            // Check if the document exists
            if (docSnap.exists()) {
                // Document data will be in docSnap.data()
                completion(docSnap.data())
            } else {
                completion(null)
                // Document not found
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    }

    static async sendPushTouser(user, title, message) {

        if (user.token != null) {
            const sendPush = httpsCallable(functions, 'sendPushNotification');
            try {
                const result = await sendPush({ fcmToken: user.token, title: title, body: message });
                //console.log(result.data);
            } catch (error) {
                console.error('Error calling function: ', error);
            }
        }
        FBQueries.addNotification(user.uid, title, message)
    }


    static async addNotification(uid, title, description) {
        try {
            // Reference the collection
            const docRef = await addDoc(collection(db, 'notifications'), {
                title: title,
                description: description,
                uid: uid,
                createdAt: serverTimestamp()
            });
            console.log('Document written with ID: ', docRef.id);
            //alert('Document added successfully!');
        } catch (e) {
            console.error('Error adding document: ', e);
            //alert('Error adding document');
        }
    };

    static async addAmountinWallet(amount, uid) {
        try {
            const collectionRef = collection(db, "mywallet");
            const q = query(collectionRef, where("uid", "==", uid));
            const querySnapshot = await getDoc(q);
            querySnapshot.forEach(async (doc) => {
                let data = doc.data()
                var bal = data.balance
                bal = Number(bal) + Number(amount)
                let updatedata = {
                    balance: bal,
                }
                const docRef = doc(db, 'mywallet', data.walletid); // Replace with your collection name
                await updateDoc(docRef, updatedata);
            });
        } catch (error) {
            //LogTrack.SetLoggingEvent(error.message, Status.BadRequest)
        }
    };

    // static async deleteDocumentsWithCondition(bookingID) {
    //     const collectionName = 'pendingBookings';
    //     const field = 'bookingID';
    //     const condition = '==';
    //     const value = bookingID;

    //     try {
    //       // Create a query with the `where` condition
    //       const q = query(
    //         collection(db, collectionName),
    //         where(field, condition, value)
    //       );

    //       // Execute the query
    //       const querySnapshot = await getDocs(q);

    //       if (querySnapshot.empty) {
    //         console.log('No matching documents found');
    //         return;
    //       }

    //       // Loop through documents and delete each one
    //       const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
    //       await Promise.all(deletePromises);

    //       console.log('Documents deleted successfully');
    //     } catch (error) {
    //       console.error("Error deleting documents: ", error);
    //     }
    //   };
}