import React, { useState } from "react";

const ModifyBookingPopup = ({actualprice,actualeta, approve, cancel}) => {
    const [price, setPrice] = useState(actualprice);
    const [eta, setEta] = useState(actualeta);


  // Handler for input changes (only allow number input)
  const handlePriceChange = (e) => {
    setPrice(e.target.value); // Only numbers and comma allowed
  };


  const handleEtaChange = (e) => {
    setEta(e.target.value); // Only numbers allowed
  };

  return (
    <div style={styles.container}>      
        <div style={styles.overlay}>
          <div style={styles.popup}>
            <h3 style={styles.heading}>Modify Booking</h3>

            <label htmlFor="price" style={styles.label}>Change price:</label>
            <input onChange={handlePriceChange} type="text" id="price" value={price} style={styles.input} />
            {/* <div style={styles.inputWrapper}>
                <input
                  type="text"
                  value={price}
                  onChange={handlePriceChange}
                  style={styles.input}
                />
                <span style={styles.fixedText}>AED</span>
              </div> */}

            <label htmlFor="eta" style={styles.label}>ETA:</label>
            <select defaultValue={eta} id="eta" style={styles.select} onChange={handleEtaChange}>
              <option value="10 min">10 min</option>
              <option value="15 min">15 min</option>
              <option value="20 min">20 min</option>
              <option value="25 min">25 min</option>
              <option value="30 min">30 min</option>
              <option value="35 min">35 min</option>
              <option value="40 min">40 min</option>
              <option value="45 min">45 min</option>
              <option value="50 min">50 min</option>
              <option value="55 min">55 min</option>
              <option value="60 min">60 min</option>
            </select>

            <div style={styles.buttonRow}>
              <button onClick={cancel} style={styles.cancelButton}>Cancel</button>
              <button style={styles.approveButton} onClick={()=>{
                approve(price, eta)
              }} >Approve with Modifications</button>
            </div>
          </div>
        </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
  },
  changeButton: {
    padding: "10px 20px",
    backgroundColor: "#d4d4d4",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  popup: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    width: "300px",
    textAlign: "center",
    transform: "scale(0.8)",  // Initial scale (for animation)
    opacity: 0,  // Initial opacity (for animation)
    animation: "popupAnimation 0.3s forwards", 
  },
  heading: {
    fontSize: "18px",
    marginBottom: "15px",
  },
  label: {
    display: "block",
    textAlign: "left",
    marginBottom: "8px",
    fontSize: "14px",
  },
  input: {
    width: "93%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "15px",
  },
  select: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "15px",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  cancelButton: {
    padding: "10px 20px",
    backgroundColor: "#fff",
    border: "1px solid #00a859",
    borderRadius: "5px",
    cursor: "pointer",
  },
  approveButton: {
    padding: "10px 20px",
    backgroundColor: "#34977D",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  fixedText: {
    position: 'absolute',
    right: '10px',
    fontSize: '16px',
    color: '#333',
  },
};


// Add keyframes for animation
const styleSheet = document.styleSheets[0];
const keyframes =
  `@keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }`;

styleSheet.insertRule(keyframes, styleSheet.cssRules.length);


export default ModifyBookingPopup;
