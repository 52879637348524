// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration (find this in Firebase console under Project Settings)
const firebaseConfig = {
  apiKey: "AIzaSyAgCwGcvb4i83DgTV-UHFwaJtNp1D3hN8g",
  authDomain: "drvr-app.firebaseapp.com",
  projectId: "drvr-app",
  storageBucket: "drvr-app.appspot.com",
  messagingSenderId: "913961001052",
  appId: "1:913961001052:web:7b2d40c7b5900a9278c699",
  measurementId: "G-ZPVB6BJRRF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Functions
const functions = getFunctions(app);
// Initialize Firestore
const db = getFirestore(app);

export { db, functions, httpsCallable };
