import React, { useState, useEffect } from "react";
import ModifyBookingPopup from "./ModifyBookingPopup";
import FBQueries from "../Services/FBQueries";
import { useParams } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../Services/firebase'; // Adjust the import path as necessary
import { sendMessage } from '../UltraMag/Ultramag'
import logo from '../Assets/animatedLogo.webp'

const Booking = () => {
  const { bookingid } = useParams();
  const [bookingData, setBookingData] = useState({ from: "", to: '', arivalTime: '', price: '', eta: '', clientName: '', clientNumber: '', plate: '' });
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [shouldListen, setShouldListen] = useState(true);
  const [iscompleted, setCompleted] = useState(false);
  const [status, setStatus] = useState('pending');
  const [price, setPrice] = useState('');
  const [eta, setEta] = useState('');
  const [client, setClient] = useState({ fullName: '', formattedValue: '' });
  const [isBlurred, setIsBlurred] = React.useState(true);
  const [isLoading, setLoading] = useState(true);

  const ultraMagToken = '3dppz3g7l2leqg34'
  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    //if (!shouldListen) return;
    const q = query(
      collection(db, 'bookings'),
      where('bookingID', '==', bookingid)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(change => {
        const docData = {
          id: change.doc.id,
          ...change.doc.data(),
        };
        if (change.type === 'added') {

          fetchUserDetail(docData.uid)
          setBookingData(docData)
          setPrice(docData.price)
          setEta(docData.eta)
          if (docData.status == 'completed') {
            setCompleted(true)
            setStatus('completed')
            return
          }
          else if (docData.status == 'cancelled') {
            setCompleted(true)
            setStatus('cancelled')
            return
          }

          if (docData.approved) {
            setCompleted(true)
            setStatus('approved')
            return
          }
          else if (docData.rejected) {
            setCompleted(true)
            setStatus('rejected')
            return
          }
        }
        if (change.type === 'modified') {
          if (docData.status == 'completed' || docData.status == 'cancelled') {
            setStatus(docData.status)
            return
          }
          if (docData.approved == true) {
            setStatus('approved')
            setCompleted(true)
            return
          }
          else if (docData.rejected == true) {
            setStatus('rejected')
            setCompleted(true)
            return
          }
          if (docData.modificationAccepted == 'accepted') {
            setStatus('approved')
            alert("Modifications are accepted by user");
          }
          else if (docData.modificationAccepted == 'rejected') {
            setStatus('rejected')
            alert("Modifications are not accepted by user");
          }
        }
      });
    }, (error) => {
      console.error("Error fetching data: ", error);
    });


    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  function fetchUserDetail(uid) {

    FBQueries.fetchUserDetails(uid, (data) => {
      setLoading(false)
      if (data) {
        setClient(data)
      }
      else {
        alert("Error fetching this user. Please try again later.");
      }
    })
  }


  const acceptBooking = () => {
    FBQueries.approveBooking(bookingid, client, async (success) => {
      if (success) {
        FBQueries.sendPushTouser(client, 'Approved 👍', 'Your booking has been approved 🎉', (success) => {
          if (success) {
          }
        })
        let mapLink = `https://www.google.com/maps?q=${bookingData.fromCordinates.latitude},${bookingData.fromCordinates.longitude}`
        let message = `Booking confirmed:\nWhen: ${bookingData.arivalTime}\nFrom: ${bookingData.from}\nTo: ${bookingData.to}\nCustomer number: ${client.formattedValue}\nCar: ${bookingData.carname} - ${bookingData.plate}\nLocation:${mapLink}`
        const messageData = {
          token: ultraMagToken,
          to: client.formattedValue,
          body: message,
        };
        const response = await sendMessage(messageData);
        if (response) {
        }

      }
    })
  };

  const rejectBooking = () => {
    FBQueries.rejectBooking(bookingid, async (success) => {
      if (success) {
        FBQueries.sendPushTouser(client, 'Rejected 😔', 'Your booking has been rejected', (success) => {
          if (success) {
          }
        })
        FBQueries.addAmountinWallet(price.replace("AED ", ""), client.uid, (success) => {
        })
        let message = `Booking rejected:\nWhen: ${bookingData.arivalTime}\nFrom: ${bookingData.from}\nTo: ${bookingData.to}`
        const messageData = {
          token: ultraMagToken,
          to: client.formattedValue,
          body: message,
        };
        const response = await sendMessage(messageData);
        if (response) {
        }

      }
    })
  };

  const ModifyBooking = (updatedprice, eta) => {
    setPopupVisible(false);
    FBQueries.modifyBooking(bookingid, eta, price, updatedprice, (success) => {
      if (success) {
        if (updatedprice == price) {
          acceptBooking()
        }
        else {
          FBQueries.sendPushTouser(client, 'Modification Request', 'Your booking has been modified', (success) => {
          })
        }
      }
    })
    setPrice(updatedprice)
    setEta(eta)

  };


  const contactSupport = () => {
    window.location.href = 'mailto:support@drvr.ae?subject=Need%20Help&body=Your%20Type Your Message Here';
  };

  const handleCopy = (e) => {
    if (status != 'approved' || status != 'completed') {
      e.preventDefault();
      //alert("Copying is disabled while input is blurred.");
    }
  };

  return (
    <div>
      {isLoading ? (<div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>) : (
        <div style={styles.container}>
          <h2 style={styles.heading}>New booking request:</h2>
          <p style={styles.status}>
            Status: <span style={styles.pending}>{status}</span>
          </p>

          <div style={styles.form}>
            <div style={styles.row}>
              <div style={styles.column}>
                <label htmlFor="fromAddress" style={styles.label}>From address:</label>
                <input type="text" id="fromAddress" value={bookingData.from} style={styles.input} readOnly />
              </div>
              <div style={styles.column}>
                <label htmlFor="toAddress" style={styles.label}>To address:</label>
                <input type="text" id="toAddress" value={bookingData.to} style={styles.input} readOnly />
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label htmlFor="when" style={styles.label}>When:</label>
                <input type="text" id="when" value={bookingData.arivalTime} style={styles.input} readOnly />
              </div>
              <div style={styles.column}>
                <label htmlFor="price" style={styles.label}>Price:</label>
                <input type="text" id="price" value={price} style={styles.input} readOnly />
              </div>
              <div style={styles.column}>
                <label htmlFor="eta" style={styles.label}>ETA From Approved:</label>
                <input type="text" id="eta" value={eta} style={styles.input} readOnly />
                {/* <select id="eta" style={styles.input}>
              <option value="10 min">10 min</option>
              <option value="15 min">15 min</option>
              <option value="20 min">20 min</option>
              <option value="25 min">25 min</option>
              <option value="30 min">30 min</option>
              <option value="35 min">35 min</option>
              <option value="40 min">40 min</option>
              <option value="45 min">45 min</option>
              <option value="50 min">50 min</option>
              <option value="55 min">55 min</option>
              <option value="60 min">60 min</option>
            </select> */}
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <label htmlFor="clientName" style={styles.label}>Client name:</label>
                <input type="text" id="clientName" value={client.fullName} style={{
                  filter: (status == 'approved' || status == 'completed') ? 'none' : "blur(5px)", transition: "filter 0.3s",
                  userSelect: "none", width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px"
                }} readOnly onCopy={handleCopy} onMouseDown={handleCopy} />
              </div>
              <div style={styles.column}>
                <label htmlFor="phoneNumber" style={styles.label}>Client phone number:</label>
                <input type="text" id="phoneNumber" value={client.formattedValue} style={{
                  filter: (status == 'approved' || status == 'completed') ? 'none' : "blur(5px)", transition: "filter 0.3s",
                  userSelect: "none", width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px"
                }} readOnly onCopy={handleCopy} onMouseDown={handleCopy} />
              </div>
              <div style={styles.column}>
                <label htmlFor="plate" style={styles.label}>Plate:</label>
                <input type="text" id="plate" value={bookingData.plate} style={{
                  filter: (status == 'approved' || status == 'completed') ? 'none' : "blur(5px)", transition: "filter 0.3s",
                  userSelect: "none", width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px"
                }} readOnly onCopy={handleCopy} onMouseDown={handleCopy} />
              </div>
            </div>

            {!iscompleted && <div style={styles.buttonRow}>
              <button style={styles.rejectButton} onClick={rejectBooking}>Reject booking</button>
              <button style={styles.changeButton} onClick={openPopup}>Change in booking</button>
              <button style={styles.approveButton} onClick={acceptBooking}>Approve</button>
            </div>}

            {iscompleted && status == 'rejected' && <div style={styles.buttonRow}>
              <button style={styles.rejectButton} onClick={contactSupport}>Rejected By mistake ? Contact support</button>
            </div>}
          </div>


          {isPopupVisible && (

            <ModifyBookingPopup actualprice={price} actualeta={eta} approve={ModifyBooking} cancel={closePopup}></ModifyBookingPopup>
          )}
        </div>)}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "10px",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  status: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  form: {
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  column: {
    flex: "1",
    minWidth: "200px",
    padding: "10px",
    margin: '10px'
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",  // Align buttons to the right
    gap: "10px",  // Add spacing between buttons
    flexWrap: "wrap",
  },
  rejectButton: {
    backgroundColor: "#fff",  // Set same background for all buttons as in the image
    color: "#000",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "10px",
  },
  changeButton: {
    backgroundColor: "#34977D",  // Set same background for all buttons as in the image
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "10px",
  },
  approveButton: {
    backgroundColor: "#34977D",  // Set same background for all buttons as in the image
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "10px",
  },
  pending: {
    color: "black",
  }
};

// Media query styles for mobile responsiveness
const mediaQueryStyles = `
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .buttonRow {
    flex-direction: column;
    align-items: stretch;
  }
  .rejectButton, .changeButton, .approveButton {
    width: 100%;
    margin-bottom: 10px;
  }
}
`;

export default Booking;
