import './App.css';
import Booking from './Bookings/Booking';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home/Home';
function App() {
  return (
    <Router>
      <Routes>
        {/* Route with dynamic 'orderid' */}
        <Route path="/" element={<Home />} />
        <Route path="/:bookingid" element={<Booking />} />
      </Routes>
    </Router>
  );
}

export default App;
