// LogoPage.js
import React from 'react';
import logo from '../Assets/animatedLogo.webp'
const Home = () => {
  return (
    <div className="logo-container">
      <img src={logo} alt="Logo" className="logo" />
    </div>
  );
};

export default Home;
