import React from 'react';
import axios from 'axios';

// Create an instance of axios with default settings
const api = axios.create({
  baseURL: 'https://api.ultramsg.com', // Base URL for UltraMsg API
  headers: {
    'Content-Type': 'application/json',
  }
});

// Function to send a message
export const sendMessage = async (messageData) => {
  try {
    const response = await api.post(`/instance96601/messages/chat`, messageData);
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error; // Rethrow the error to handle it later
  }
};

// Function to fetch messages (if supported by the API)
export const fetchMessages = async () => {
  try {
    const response = await api.get(`/instance96601/messages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error; // Rethrow the error to handle it later
  }
};
